import { render, staticRenderFns } from "./MiniSpinner.vue?vue&type=template&id=56d48d59&scoped=true&"
import script from "./MiniSpinner.vue?vue&type=script&lang=js&"
export * from "./MiniSpinner.vue?vue&type=script&lang=js&"
import style0 from "./MiniSpinner.vue?vue&type=style&index=0&id=56d48d59&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56d48d59",
  null
  
)

export default component.exports